// import HeaderMenu from "./components/menu/HeaderMenu"
import SearchBox from "./components/search/SearchBox"
// import ExpansionPanel from "./components/ExpansionPanel/ExpansionPanel"
import NewsletterButton from "./components/button/NewsletterButton"
import SocialButton from "./components/button/SocialButton"
import ExternalLink from "./components/button/ExternalLink"
import ModuleLoader from "./components/module/ModuleLoader"
// import ViewportConfig from './components/viewport/ViewportConfig'
// customElements.define('ce-header-menu', HeaderMenu)
customElements.define('ce-search-box', SearchBox)
// customElements.define('ce-expansion-panel', ExpansionPanel)
customElements.define('ce-newsletter-button', NewsletterButton)
customElements.define('ce-module-loader', ModuleLoader)
customElements.define('ce-social-button', SocialButton)
customElements.define('ce-external-link', ExternalLink)

// new ViewportConfig()
let rootEl = document.querySelector('body')
rootEl.addEventListener('click', evt => {
  // console.log('body click:', event.target)
  let targetEl = event.target
  if (targetEl.closest('nav .hamburger-icon')) {
    let body = document.querySelector("body")
    let menu = document.querySelector("nav")
    menu.classList.toggle("open-hamburger")
    body.classList.toggle("overflow-hidden")
    // console.log('hamburger')
  } else if (targetEl.closest('nav .search-icon')) {
    let body = document.querySelector("body")
    let menu = document.querySelector("nav")
    let searchBox = document.querySelector("nav ce-search-box")
    let isOpen = menu.classList.contains("open-search")
    menu.classList.toggle("open-search")
    menu.classList.toggle("open-hamburger")
    body.classList.toggle("overflow-hidden")
    if (!isOpen) {
      let focusEvt = new Event('focus')
      searchBox.dispatchEvent(focusEvt)
    }
    // console.log('search')
  } else if (targetEl.closest('nav .menu-item')) {
    let menuItem = targetEl.closest('.menu-item')
    menuItem.classList.toggle("show")
  }
})

window.onload = () => {
  // get all hyperlinks from the document
  let hyperlinks = document.querySelectorAll('a')
  for (let i = 0; i < hyperlinks.length; i++) {
    let hyperlink = hyperlinks[i]
    let href = hyperlink.href
    let tempAnchor = document.createElement('a')
    tempAnchor.href = href
    let hostname = tempAnchor.hostname
    // check each hyperlink for webmd or medicinenet domain
    // if (hostname && !hostname.endsWith('webmd.com') && !hostname.endsWith('medicinenet.com')) {
    if (hostname && !/(webmd.com|medicinenet.com|rxlist.com|emedicinehealth.com|truste.com|internetbrands.com?)$/.test(hostname) ) {
      // wrap external link into ce-external-link element
      let parentEl = hyperlink.parentElement
      let wrapper = document.createElement('ce-external-link')
      wrapper.appendChild(hyperlink)
      parentEl.appendChild(wrapper)
    }
  }
}
// window.onload = () => {
//   const body = document.querySelector("body")
//   const menu = document.querySelector("nav")
//   const hamburgerIcon = document.querySelector("nav .hamburger-icon")
//   const searchIcon = document.querySelector("nav .search-icon")
//   const menuItems = document.querySelectorAll("nav .menu-item")
//   const searchBox = document.querySelector("nav ce-search-box")
//   hamburgerIcon.addEventListener("click", (e) => {
//     menu.classList.toggle("open-hamburger")
//     body.classList.toggle("overflow-hidden")
//   })
//   searchIcon.addEventListener("click", (e) => {
//     let isOpen = menu.classList.contains("open-search")
//     menu.classList.toggle("open-search")
//     menu.classList.toggle("open-hamburger")
//     body.classList.toggle("overflow-hidden")
//     if (!isOpen) {
//       let evt = new Event('focus')
//       searchBox.dispatchEvent(evt)
//     }
//   })
//   menuItems.forEach((item) =>
//     item.addEventListener("click", (e) => {
//       e.target.closest(".menu-item").classList.toggle("show")
//     })
//   )
// }